import { object, string, SchemaOf, mixed } from "yup";
import { FC, useState } from "react";
import { useFormikContext } from "formik";
import {
  Button,
  FormField,
  Select,
  notification,
  useCall,
  isEmpty,
  useDidMount,
} from "@epcnetwork/core-ui-kit";

import { testJSONValidity } from "utils";
import { SelectOption } from "models";
import { requiredFieldText } from "constants/form.constants";
import { TabOption, Tabs } from "components";
import { getDataExtensions, getSalesforceLists } from "api";
import { InitialValues } from "../jobs-form.types";

import styles from "../job-form.module.css";
import labelStyles from "./connectors.module.css";

export const salesforceSchema: SchemaOf<SalesforceType> = object({
  clientId: string().required(requiredFieldText),
  clientSecret: string().required(requiredFieldText),
  origin: string().required(requiredFieldText),
  authOrigin: string().required(requiredFieldText),
  soapOrigin: string().required(requiredFieldText),
  type: string().oneOf(["dataExtension", "list"]).required(requiredFieldText),
  listId: string().required(requiredFieldText),
  custom_attributes: mixed()
    .required(requiredFieldText)
    .test("json", "JSON is invalid", (value) => testJSONValidity(value)),
});

type DataExtensionType = SelectOption<string>;

export type SalesforceType = {
  clientId: string;
  clientSecret: string;
  origin: string;
  authOrigin: string;
  soapOrigin: string;
  type: string;
  listId: string;
  custom_attributes: string;
};

type SalesforceProps = {
  endpointOrdinal: number;
};

const Salesforce: FC<SalesforceProps> = ({ endpointOrdinal }) => {
  const { values, errors, setFieldValue, submitCount } = useFormikContext<InitialValues>();

  const [dataExtensionOptions, setDataExtensionOptions] = useState<DataExtensionType[]>([]);
  const [salesforceLists, setSalesforceLists] = useState<DataExtensionType[]>([]);

  const salesforceValues = values.endpoints[endpointOrdinal]?.connector.properties as
    | SalesforceType
    | undefined;

  const { clientId, clientSecret, origin, authOrigin, soapOrigin, listId } = salesforceValues || {};

  const getDataExtensionsCall = useCall(getDataExtensions);
  getDataExtensionsCall.onCallSuccess((payload) => {
    if (!payload || !payload.length)
      return notification.warning("No Data", "Please check credentials and try again");

    const optionsToSave: DataExtensionType[] = payload.map(({ listId, name }) => ({
      value: listId ?? "",
      label: name,
    }));

    notification.success("Data extensions fetched!", "Select your data extension.");
    setDataExtensionOptions(optionsToSave);
  });
  getDataExtensionsCall.onCallError(() => {
    notification.error("Fetching error", "Some error happened, please try again");
  });

  const getSalesforceListsCall = useCall(getSalesforceLists);
  getSalesforceListsCall.onCallSuccess((payload) => {
    if (!payload || !payload.length)
      return notification.warning(
        "No Salesforce lists",
        "Please check Salesforce credentials and try again",
      );

    const optionsToSave: DataExtensionType[] = payload.map(({ listId, name }) => ({
      value: listId ?? "",
      label: name,
    }));

    notification.success("Salesforce lists fetched!", "Select your Salesforce list.");
    setSalesforceLists(optionsToSave);
  });
  getSalesforceListsCall.onCallError(() => {
    notification.error("Fetching error", "Some error happened, please try again");
  });

  const isListDisabled =
    !clientId ||
    !clientSecret ||
    !origin ||
    !authOrigin ||
    !soapOrigin ||
    getDataExtensionsCall.submitting ||
    getSalesforceListsCall.submitting;

  useDidMount(() => {
    if (!isListDisabled) {
      handleGetDataExtensionsClick();
    }
  });

  const handleGetDataExtensionsClick = () => {
    setDataExtensionOptions([]);

    getDataExtensionsCall.submit({
      data: {
        clientId: clientId?.trim(),
        clientSecret: clientSecret?.trim(),
        origin: origin?.trim(),
        authOrigin: authOrigin?.trim(),
        soapOrigin: soapOrigin?.trim(),
      },
    });
  };

  const handleGetSalesforceListsClick = () => {
    setSalesforceLists([]);

    getSalesforceListsCall.submit({
      data: {
        clientId: clientId?.trim(),
        clientSecret: clientSecret?.trim(),
        origin: origin?.trim(),
        authOrigin: authOrigin?.trim(),
        soapOrigin: soapOrigin?.trim(),
      },
    });
  };

  const handleTypeChange = ({ value }: TabOption<string>) => {
    setFieldValue(`endpoints[${endpointOrdinal}].connector.properties.type`, value);
  };

  const handleListSelect = (option: SelectOption<string>) => {
    const { value } = option || {};
    setFieldValue(`endpoints[${endpointOrdinal}].connector.properties.listId`, value || "");
  };

  const errorsAny: any = errors;
  const error =
    (!isEmpty(errorsAny) &&
      !isEmpty(errorsAny.endpoints) &&
      errorsAny?.endpoints[endpointOrdinal]?.connector?.properties?.listId) ||
    "";
  const errorText = !!submitCount && error ? error : "";

  const selectedType =
    // @ts-ignore
    values.endpoints[`${endpointOrdinal}`].connector.properties?.type || "dataExtension";

  return (
    <>
      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.clientId`}
        label="Client ID"
        placeholder="Client ID"
      />
      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.clientSecret`}
        label="Client Secret"
        placeholder="Client Secret"
      />
      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.origin`}
        label="Origin"
        placeholder="Origin"
      />
      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.authOrigin`}
        label="Auth Origin"
        placeholder="Auth Origin"
      />
      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.soapOrigin`}
        label="Soap Origin"
        placeholder="Soap Origin"
      />

      <Tabs
        selected={selectedType}
        onChange={handleTypeChange}
        options={[
          { value: "dataExtension", label: "Data extension" },
          { value: "list", label: "List" },
        ]}
      />
      <Select
        label={selectedType === "list" ? "List" : "Data extension"}
        placeholder={selectedType === "list" ? "Select Salesforce list" : "Select data extension"}
        name={`endpoints[${endpointOrdinal}].connector.properties.listId`}
        error={errorText}
        selectedOptionsKeys={listId}
        options={selectedType === "list" ? salesforceLists : dataExtensionOptions}
        onChange={handleListSelect}
        disabled={isListDisabled}
      />
      <Button
        disabled={isListDisabled}
        onClick={
          selectedType === "list" ? handleGetSalesforceListsClick : handleGetDataExtensionsClick
        }
        className={styles.btnDataExtensions}
      >
        {selectedType === "list" ? "Get Salesforce lists" : "Get data extensions"}
      </Button>
      <FormField
        type="code"
        name={`endpoints[${endpointOrdinal}].connector.properties.custom_attributes`}
        label="ET JSON Body:"
        className={labelStyles.headersLabel}
      />
    </>
  );
};

export { Salesforce };
